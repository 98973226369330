import React, { useContext } from "react";
import { Avatar, Box, Button, Container, Fade, Grid, makeStyles, Typography, useMediaQuery, Link as ELink } from "@material-ui/core";
import { Element } from "react-scroll";
import ScrollAnimation from "react-animate-on-scroll";
import moment from "moment";
import { Info } from "../../../../utils/DifuntContext";
import { Link } from "react-scroll";
import { IconButton } from "@material-ui/core";
import Icona from "../../../../components/Icona";
import PerfilSkeleton from "../../../../components/Skeletons/PerfilSkeleton";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as Export } from "../../../../theme/icons/export.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { MapPin } from "react-feather";

const useStyles = makeStyles((theme) => ({
	main: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		height: "100vh",
	},
	mainM: {
		paddingTop: 100,
		paddingBottom: 100,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	container: {
		paddingBottom: 100,
	},
	box: {
		borderRadius: 20,
		backgroundColor: "white",
		padding: 60,
	},
	anys: {
		fontWeight: 800,
		color: theme.palette.primary.main,
		marginRight: 15,
	},
	imatge: {
		width: "100%",
		aspectRatio: 1 / 1,
		height: "auto",
		borderRadius: 20,
	},
	nom: {
		marginRight: 15,
		fontSize: 30,
	},
	sobrenom: {
		fontSize: 25,
		fontWeight: 100,
	},
	titol: {
		fontWeight: 800,
		marginTop: 20,
	},
	root: {
		fontSize: "0,5rem",
		border: "2px solid #E5E3DB",
		marginRight: 20,
		justifyContent: "center",
		width: "100%",
		borderRadius: 20,
	},
	button: {
		display: "flex",
	},
	data_anys: {
		display: "flex",
		alignItems: "flex-end",
	},
	data_anysM: {
		display: "content",
	},
	exportar: {
		fontSize: "0,5rem",
		border: "2px solid #E5E3DB",
		marginRight: 20,
		justifyContent: "center",
	},
	icon: { padding: 9 },
	media: {
		width: "100%",
		objectFit: "contain",
	},
	imageWrap: {
		borderRadius: 20,
		overflow: "hidden",
	},
	buttonC: {
		boxShadow: "none",
		borderRadius: 30,
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 30,
		paddingRight: 30,
		textTransform: "none",
	},
}));

const Perfil = ({ dataCerimonia, loadingFlors, loading }) => {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	const { difunt } = useContext(Info);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const exportar = () => {
		enqueueSnackbar(t("S'ha copiat l'enllaç per compartir"), {
			variant: "success",
		});
	};

	return (
		<Element name="perfil" className={matches ? classes.main : classes.mainM}>
			<Container maxWidth="lg" className={matches ? classes.container : ""}>
				<ScrollAnimation animateIn="fadeIn" style={{ transition: "0.5s" }}>
					<Box className={classes.box}>
						{!loading && !loadingFlors ? (
							difunt ? (
								<Grid container spacing={10} style={{ position: "relative" }}>
									<Grid item md={3} style={{ paddingTop: !matches && 80 }}>
										{difunt?.imatge && (
											<Box className={classes.imageWrap}>
												<img
													src={"https://api.funerariaferran.com/public/storage/" + difunt?.imatge}
													className={classes.media}
													alt="flor"
												/>
											</Box>
											// <Avatar
											//     variant="square"
											//     className={classes.imatge}
											//     src={
											//         'https://api.funerariaferran.com/public/storage/' +
											//         difunt?.imatge
											//     }
											// />
										)}
									</Grid>
									<Grid item sm={7}>
										<Fade in={!loading}>
											<Grid item xs={12}>
												<Box display="flex" flexDirection={"column"} pb={2}>
													<Typography variant="h2" className={classes.nom}>
														{difunt?.nom} {difunt?.cognoms}
													</Typography>
													{difunt?.sobrenom && (
														<Typography variant="h2" className={classes.sobrenom}>
															({difunt?.sobrenom})
														</Typography>
													)}
												</Box>
												<Box className={matches ? classes.data_anys : classes.data_anysM}>
													<Typography className={classes.anys}>
														{difunt?.data_naixement && moment(difunt?.data_naixement).format("DD/MM/YYYY") + " - "}

														{moment(difunt?.data_defuncio).format("DD/MM/YYYY")}
													</Typography>
													{difunt?.data_naixement && (
														<Typography>
															({moment(difunt?.data_defuncio).diff(moment(difunt?.data_naixement), "years")} {t("anys")})
														</Typography>
													)}
												</Box>
												<Typography>{difunt?.viudu}</Typography>
												{difunt?.tanatori_id !== null && difunt?.tanatori_id !== 0 && (
													<>
														<Typography className={classes.titol}>{t("Sales de vetlla")}:</Typography>
														{difunt?.tanatori_id === 1000 ? (
															<>
																<Typography>{difunt?.tanatori_extern} </Typography>
																<Typography>{difunt?.horari_tanatori}</Typography>
															</>
														) : (
															<>
																<Typography style={{ display: "flex" }}>
																	{difunt?.tanatori?.nom} de {difunt?.tanatori?.poblacio}{" "}
																	{difunt?.sala && "(" + t("Sala") + " " + difunt?.sala + ")"}
																	<ELink
																		style={{
																			marginTop: 3,
																			marginLeft: 5,
																		}}
																		className={classes.link}
																		target="_blank"
																		href={difunt?.tanatori?.link}
																	>
																		<MapPin size={18} color="gray" />
																	</ELink>
																</Typography>
																<Typography>{difunt?.horari_tanatori}</Typography>
															</>
														)}
													</>
												)}
												{difunt?.dia_cerimonia && (
													<>
														<Typography className={classes.titol}>{t("Cerimònia")}:</Typography>
														<Typography>
															<span
																style={{
																	textTransform: "capitalize",
																}}
															>
																{moment(difunt?.dia_cerimonia).format("dddd")}{" "}
															</span>
															dia {moment(difunt?.dia_cerimonia).format("LL")}
															{t(" a les ")}
															{moment(difunt?.hora_cerimonia, "HH:mm:ss").format("HH:mm")}
															{" h "}
														</Typography>
														<Typography>{difunt?.cerimonia}</Typography>
													</>
												)}
												{difunt?.desti && (
													<>
														<Typography className={classes.titol}>{t("Destí")}:</Typography>
														<Typography>{difunt?.desti}</Typography>
													</>
												)}
												{difunt?.publicat === 1 && (
													<Grid
														container
														spacing={3}
														style={{
															marginTop: 20,
														}}
													>
														{Number(difunt?.flors_notes) ? (
															<Grid item md={6} xs={12}>
																<Link
																	className={classes.button}
																	to={"flors"}
																	spy={true}
																	smooth={true}
																	offset={-150}
																	duration={500}
																>
																	<IconButton size="small" className={classes.root}>
																		<Icona type="flor" />
																		<Typography>{t("Ofrena floral")}</Typography>
																	</IconButton>
																</Link>
															</Grid>
														) : (
															<div></div>
														)}
														{Number(difunt?.notes_visible) ? (
															<Grid item md={6} xs={12}>
																<Link
																	className={classes.button}
																	to={"notes"}
																	spy={true}
																	smooth={true}
																	duration={500}
																	offset={-150}
																>
																	<IconButton size="small" className={classes.root}>
																		<Icona type="notes" />
																		<Typography>{t("Notes de condol")}</Typography>
																	</IconButton>
																</Link>
															</Grid>
														) : (
															<div></div>
														)}
													</Grid>
												)}
											</Grid>
										</Fade>
									</Grid>
									<Grid item md={2} style={{ textAlign: "right" }}>
										<Box
											style={{
												position: "absolute",
												top: matches ? 10 : 0,
												right: matches ? 10 : 15,
											}}
										>
											<CopyToClipboard text={window.location.href} onCopy={exportar}>
												<IconButton size="small" className={classes.exportar}>
													<SvgIcon className={classes.icon} component={Export} viewBox={"0 0 10 10"} />
												</IconButton>
											</CopyToClipboard>
										</Box>
									</Grid>
								</Grid>
							) : (
								<Box display={"block"}>
									<Box pb={3}>
										<Typography variant="h2">{t("La necrològica ja no està disponible")}</Typography>
									</Box>
									<Typography variant="body1" pt={2}>
										{t("Per a més informació")}:
									</Typography>
									<Box my={2}>
										<Button
											color="primary"
											className={classes.buttonC}
											variant="contained"
											onClick={() =>
												navigate("/", {
													state: {
														scroll: "contacte",
													},
												})
											}
										>
											{t("Contacte")}
										</Button>
									</Box>
								</Box>
							)
						) : (
							<PerfilSkeleton />
						)}
					</Box>
				</ScrollAnimation>
			</Container>
		</Element>
	);
};

export default Perfil;
