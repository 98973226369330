import { Box, Tooltip } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Switch } from "@material-ui/core";
import moment from "moment";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { Close, Edit } from "@material-ui/icons";
import { changeArxivada, changeFlors, changeNotes, changePublicada } from "../../../../database/API";
import { Check, Eye } from "react-feather";
import { Avatar } from "@material-ui/core";

const Columns = () => {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: false,
				display: false,
			},
		},
		{
			name: "slug",
			label: "SLUG",
			options: {
				filter: false,
				sort: false,
				display: false,
			},
		},
		{
			name: "imatge",
			label: "Fotografia",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => <Avatar variant="circular" src={"https://api.funerariaferran.com/public/storage/" + value} />,
			},
		},
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "cognoms",
			label: "Cognoms",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "data_defuncio",
			label: "Data Defunció",
			options: {
				filter: false,
				sort: true,
				sortOrder: "asc",
				customBodyRender: (value) => moment(new Date(value)).format("DD/MM/YYYY"),
			},
		},
		// {
		//     name: 'tanatori.nom',
		//     label: 'Tanatori',
		//     options: {
		//         filter: false,
		//         sort: true,
		//     },
		// },
		// {
		//     name: 'horari_tanatori',
		//     label: 'Horari',
		//     options: {
		//         filter: false,
		//         sort: true,
		//     },
		// },
		{
			name: "cerimonia",
			label: "Lloc de cerimònia",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "dia_cerimonia",
			label: "Dia cerimònia",
			options: {
				filter: false,
				sort: true,
				display: true,
				customBodyRender: (value) => {
					return value ? moment(value).format("DD/MM/YYYY") : "";
				},
			},
		},
		{
			name: "hora_cerimonia",
			label: "Hora cerimònia",
			options: {
				filter: false,
				sort: true,
				display: true,
				customBodyRender: (value) => {
					return value ? moment(value, "HH:mm:ss").format("HH:mm") : "";
				},
			},
		},
		{
			name: "desti",
			label: "Destí",
			options: {
				filter: false,
				sort: true,
			},
		},

		{
			name: "created_at",
			label: "Creació",
			options: {
				filter: false,
				sort: true,
				display: false,
				customBodyRender: (value) => {
					return value ? moment(value).format("DD/MM/YYYY") : "";
				},
			},
		},
		{
			name: "updated_at",
			label: "Actualitzada",
			options: {
				filter: false,
				sort: true,
				display: false,
				customBodyRender: (value) => {
					return value ? moment(value).format("DD/MM/YYYY") : "";
				},
			},
		},
		{
			name: "publicat",
			label: "Publicada",
			options: {
				filter: true,
				sort: true,
				sortOrder: "asc",
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title={value === 1 ? "Despublicar" : "Publicar"}>
							<FormControlLabel
								// label={value === "1" ? "Sí" : "No"}
								value={value === 1 ? 1 : 0}
								control={<Switch color="primary" checked={value === 1} value={value === 1 ? "1" : "0"} />}
								onChange={async (event) => {
									await changePublicada(tableMeta.rowData[0]);
									// console.log(tableMeta);
									enqueueSnackbar("Necrològica de " + tableMeta.rowData[3] + " actualitzada", {
										variant: "success",
									});
									updateValue(event.target.value === "1" ? 0 : 1);
								}}
							/>
						</Tooltip>
					);
				},
			},
		},
		{
			name: "esborrany",
			label: "Arxivat",
			options: {
				filter: true,
				sort: true,
				display: false,
				sortOrder: "asc",
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title={value === 1 ? "Desarxivar" : "Arxivar"}>
							<FormControlLabel
								// label={value === "1" ? "Sí" : "No"}
								value={value === 1 ? 1 : 0}
								control={<Switch color="primary" checked={value === 1} value={value === 1 ? "1" : "0"} />}
								onChange={async (event) => {
									await changeArxivada(tableMeta.rowData[0]);
									// console.log(tableMeta);
									enqueueSnackbar("Necrològica de " + tableMeta.rowData[3] + " actualitzada", {
										variant: "success",
									});
									updateValue(event.target.value === "1" ? 0 : 1);
								}}
							/>
						</Tooltip>
					);
				},
			},
		},
		{
			name: "flors_notes",
			label: "Mostrar flors",
			options: {
				filter: true,
				sort: true,
				display: false,
				sortOrder: "asc",
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title={value === 1 ? "Mostrar" : "Ocultar"}>
							<FormControlLabel
								// label={value === "1" ? "Sí" : "No"}
								value={value === 1 ? 1 : 0}
								control={<Switch color="primary" checked={value === 1} value={value === 1 ? "1" : "0"} />}
								onChange={async (event) => {
									await changeFlors(tableMeta.rowData[0]);
									// console.log(tableMeta);
									enqueueSnackbar("Necrològica de " + tableMeta.rowData[3] + " actualitzada", {
										variant: "success",
									});
									updateValue(event.target.value === "1" ? 0 : 1);
								}}
							/>
						</Tooltip>
					);
				},
			},
		},
		{
			name: "notes_visible",
			label: "Mostrar notes",
			options: {
				filter: true,
				sort: true,
				display: false,
				sortOrder: "asc",
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title={value === 1 ? "Desarxivar" : "Arxivar"}>
							<FormControlLabel
								// label={value === "1" ? "Sí" : "No"}
								value={value === 1 ? 1 : 0}
								control={<Switch color="primary" checked={value === 1} value={value === 1 ? "1" : "0"} />}
								onChange={async (event) => {
									await changeNotes(tableMeta.rowData[0]);
									// console.log(tableMeta);
									enqueueSnackbar("Necrològica de " + tableMeta.rowData[3] + " actualitzada", {
										variant: "success",
									});
									updateValue(event.target.value === "1" ? 0 : 1);
								}}
							/>
						</Tooltip>
					);
				},
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRender: (id, tableMeta) => {
					return (
						<Box display="flex">
							<Tooltip title="Editar">
								<IconButton
									onClick={() =>
										navigate(`/admin/difunts/${id}`, {
											state: id,
										})
									}
								>
									<Edit />
								</IconButton>
							</Tooltip>
							<Tooltip title="Veure perfil public">
								<IconButton
									onClick={() =>
										window.open(`/difunt/${tableMeta.rowData[1]}`, {
											state: tableMeta.rowData[1],
										})
									}
								>
									<Eye />
								</IconButton>
							</Tooltip>
						</Box>
					);
				},
			},
		},
	];
	return columns;
};

export default Columns;
